<template>
  <NuxtLink
    class="flex items-center gap-x-2 text-secondary btn-xs lowercase px-0"
    :to="navObjectUsed ? navigate : { name: navigate }"
  >
    <Icon name="ph:arrow-left-bold" width="12" height="11" />

    <slot> back</slot>
  </NuxtLink>
</template>

<script setup lang="ts">
  const props = defineProps<{
    navigate: string | Record<string, any>;
  }>();

  const navObjectUsed = computed(() => typeof props.navigate === "object");
</script>
